import { UserAddressType } from '@/models/common';
import {
  NeighborhoodHospitalFilter,
  NeighborhoodHospitalType,
} from '@/models/hospital';
import { FreeInfluenzaFilter } from '@/parsers/f2f/common';

import {
  BookingStatus,
  DayOfWeekType,
  DiagnosisType,
  DoctorCareerType,
  DoctorGender,
  FaceToFaceDiagnosisStatus,
  HospitalOperatingStatus,
  NonBenefitItemUnitType,
} from './bff/gql-schema';
import { Nullable } from './common';

export interface HospitalDepartmentResponse {
  tags?: string[] | null;
  departmentId: string;
  departmentName: string;
}
export interface DepartmentResponse {
  departmentId: string;
  departmentName: string;
}

export interface DiagnosisFormDoctor {
  doctorId: string;
  doctorName: string;
  displayName: string;
}

export enum ReviewFilterType {
  DOCTOR = 'DOCTOR',
  DEPARTMENT = 'DEPARTMENT',
}

export enum ReviewSortType {
  RECENT = 'RECENT',
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export interface HospitalReviewFilter {
  name: string;
  id: number;
  count: number;
}

export interface HospitalReviewFilterOption {
  doctor: HospitalReviewFilter;
  department: HospitalReviewFilter;
  sort: ReviewSortType;
}
export interface BookingTimeSlotResponse {
  startTimeSlotValue: number;
  endTimeSlotValue: number;
  displayTime: string;
}

export interface OperationDutyResponse {
  dayOfWeek: DayOfWeekType;
  openTime?: string | null;
  closeTime?: string | null;
  breakStartTime?: string | null;
  breakEndTime?: string | null;
  isClosed?: boolean | null;
  operationTimes: string;
  breakTimes: string;
}

export interface TodayDutyResponse extends OperationDutyResponse {
  operatingStatus: HospitalOperatingStatus;
}
export interface HospitalTreatmentResponse {
  id: string;
  name: string;
}

export interface DoctorReviewMetricResponse {
  averageRating: number;
  totalCount: number;
  groupByRating: {
    count: number;
    rating: number;
  }[];
  groupByDiagnosisType: {
    diagnosisType: DiagnosisType;
    diagnosisTypeId: string;
    name: string;
    count: number;
  }[];
}

export type HospitalReviewMetricResponse = Pick<
  DoctorReviewMetricResponse,
  'averageRating' | 'totalCount'
>;

export interface DoctorCareer {
  careerType: DoctorCareerType;
  content: string;
  id: string;
}
export interface HospitalDoctorResponse {
  id: string;
  hospitalId: string;
  publicId: string;
  isAbsent: boolean;
  name: string;
  gender: DoctorGender;
  profileImagePath?: string | null;
  departmentGroups: Array<DoctorDepartmentGroupResponse>;
  professionalDepartmentsV2: Array<DoctorProfessionalDepartmentResponse>;
  reviewMetric: DoctorReviewMetricResponse;
  todayDuty: {
    operatingStatus: HospitalOperatingStatus;
  };
  careers: DoctorCareer[];
  displayName: string;
  contractType?: ContractType;
  medicineType: MedicineType;
}

export type DoctorDepartmentGroupResponse = Omit<
  HospitalDepartmentGroupResponse,
  'tags'
>;

export interface DoctorProfessionalDepartmentResponse
  extends DepartmentResponse {
  departmentCode: string;
}
export interface HospitalReviewModals {
  doctor: boolean;
  department: boolean;
  sort: boolean;
}
export interface HospitalDiagnosisRequestData {
  departmentGroup: Pick<
    HospitalDepartmentGroupResponse,
    'departmentGroupId' | 'departmentGroupName'
  >;
  doctor: DiagnosisFormDoctor;
  targetDate: string;
  bookingTime: BookingTimeSlotResponse;
}

export interface HospitalReserveRequestData {
  registrationNumber: string;
  phone: string;
  name: string;
  address: string;
  detailAddress?: string;
  detailSymptom?: string;
}

export interface HospitalReserveRequestInputData {
  registrationNumber1?: string;
  registrationNumber2?: string;
  phone1?: string;
  phone2?: string;
  phone3?: string;
  name?: string;
  address?: string;
  detailAddress?: string;
  detailSymptom?: string;
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Holiday = 'HOLIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export interface HospitalProfileImageResponse {
  url: string;
  isRepresentative: boolean;
}

export interface NonBenefitDiagnosisPriceResponse {
  middleCategoryName?: string | null;
  minPrice?: number | null;
  maxPrice?: number | null;
}

export interface NaverPlaceTreatmentMenu {
  treatmentName: string;
  price: number;
}

export interface HospitalResponse {
  id: string;
  name: string;
  isBookable: boolean;
  isTodayBookable: boolean;
  badges: Array<string>;
  temporaryHolidays: Array<string>;
  temporaryHolidaysNotice?: Nullable<string>;
  longitude?: number | null;
  latitude?: number | null;
  jibunAddress?: Nullable<string>;
  roadAddress?: Nullable<string>;
  detailAddress?: Nullable<string>;
  directionGuidance?: Nullable<string>;
  parkingInformation?: Nullable<string>;
  isFreeParking?: Nullable<boolean>;
  tel?: Nullable<string>;
  viewCount: number;
  introduction?: Nullable<string>;
  profileImages: Array<HospitalProfileImageResponse>;
  duties: Array<OperationDutyResponse>;
  todayDuty: TodayDutyResponse;
  departmentGroups: Array<HospitalDepartmentGroupResponse>;
  departmentsV2: Array<DepartmentResponse>;
  nonBenefitDiagnosisPrices: Array<NonBenefitDiagnosisPriceResponse>;
  treatments: Array<HospitalTreatmentResponse>;
  partnershipTemplate: boolean;
  regionByDepth: {
    siDoName: string;
    siGunGuName?: string | null;
    eupMyeonDongName: string;
  };
  naverPlaceDetail: {
    hashtags?: Array<string> | null;
    treatmentMenus: Array<NaverPlaceTreatmentMenu>;
  };
  hospitalAdBanners: HospitalAdBannerVo[];
  hospitalSurgeries: HospitalSurgeryVo[];
}

export interface HospitalSurgeryVo {
  surgeryCount: number;
  surgeryName: string;
  badges: string[];
  id: string;
}

export interface HospitalDepartmentGroupResponse {
  averageRating: number;
  departmentGroupIconUrl: string;
  departmentGroupId: string;
  departmentGroupName: string;
  tags: string[];
}
export interface BookingTimeSlotInfo {
  doctors: HospitalDoctorResponse[];
  bookingTime: BookingTimeSlotResponse;
}

export interface FaceToFaceDiagnosisRequestFormData {
  hospitalId: string;
  doctorId: string;
  departmentGroupId: string;
  targetDate: string;
  startTimeSlotValue: number;
  endTimeSlotValue: number;
  name: string;
  phone: string;
  registrationNumber: string;
  address: string;
  detailAddress?: string;
  detailSymptom?: string;
  approvedAgreementIds: string[];
  disapprovedAgreementIds: string[];
}

export interface DepartmentReviewFilter {
  diagnosisType: DiagnosisType;
  diagnosisTypeId: string;
  name: string;
  count: number;
}
export interface DoctorReviewItem {
  reviewId: string;
  title: string;
  content: string;
  rating: number;
  createdAt: string;
  updatedAt: string;
  isPrivate: boolean;
  price: string;
  patientName: string;
  tags: {
    text: string;
  }[];
}
export enum DiagnosisState {
  On = 'On',
  Off = 'Off',
  Lunch = 'Lunch',
  DayOff = 'DayOff',
  Unknown = 'Unknown',
}

export interface HospitalProfileImageResponse {
  url: string;
  isRepresentative: boolean;
}

export interface UserCoordinate {
  latitude: number;
  longitude: number;
}

export interface AddressByLocation {
  detailAddress: string;
  jibunAddress: string;
  roadAddress: string;
  sido: string;
  zipCode: string;
  sigungu: string;
  dongLi: string;
}

export type UserSigunguDongli = Pick<AddressByLocation, 'sigungu' | 'dongLi'>;

export type SearchHospitalDepartmentGroup = {
  departmentGroupId: string;
  departmentGroupName: string;
  iconUrl: string;
};

export interface SearchHospitalVo {
  hospitalPublicId: string;
  hospitalRepresentativeImageUrl?: Nullable<string>;
  hospitalName: string;
  hospitalId: string;
  hospitalAddress: string;
  hospitalDistance?: Nullable<number>;
  hospitalBadges: Array<string>;
  hospitalOperationStatus: DiagnosisState;
  reviewScore: string;
  reviewCount: string;
  reviewTotalCount: number;
  hospitalTotalCount: number;
  hospitalRepresentativeDepartmentName: string;
  isBookable: boolean;
  doctors: Array<{ contractType: ContractType }>;
}

export interface InfluenzaHospitalVo extends SearchHospitalVo {
  nonBenefit: {
    diagnosisPrice: number;
    labels: string[];
  };
}

export interface SearchGenerallyHospitalDoctor {
  publicId: string;
  reviewAverageRating: string;
  reviewTotalCount: number;
  contractType: ContractType;
  readyForVideoCall: boolean;
  professionalDepartmentName?: string;
  doctorName: string;
  hospitalName: string;
  profileImageUrl: string;
}
export interface SearchGenerallyHospitalVo
  extends Omit<SearchHospitalVo, 'doctors'> {
  operationDisplay: string;
  hospitalAdBanners: HospitalAdBannerVo[];
  doctors: SearchGenerallyHospitalDoctor[];
}

export enum ContractType {
  All = 'ALL',
  FaceToFace = 'FACE_TO_FACE',
  NonFaceToFace = 'NON_FACE_TO_FACE',
}
export enum MedicineType {
  Counselor = 'COUNSELOR',
  Dentist = 'DENTIST',
  Doctor = 'DOCTOR',
  OrientalDoctor = 'ORIENTAL_DOCTOR',
}

export interface UserAddressVo {
  userAddressId?: string | null;
  userId: string;
  alias: string;
  roadAddress?: string | null;
  oldAddress: string;
  detailAddress: string;
  addressType: UserAddressType;
  addressTypeTitle: string;
  zipCode?: string | null;
  deliveryComment?: string | null;
  frontDoorPassword?: string | null;
  isDefault: boolean;
  location: { latitude: number; longitude: number };
}

interface OpeningHourSpecification {
  '@type': 'OpeningHoursSpecification';
  dayOfWeek: string; // 'Monday'| 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';
  opens: string; // '08:00';
  closes: string; //'17:00';
}
export interface LocalHospitalBusinessJson {
  '@context': 'https://schema.org';
  '@type': 'MedicalBusiness';
  image?: string;
  name?: string;
  aggregateRating?: {
    '@type': 'AggregateRating';
    ratingValue: string; // "4.7"
    reviewCount: string; // "152"
  };
  geo?: {
    '@type': 'GeoCoordinates';
    latitude: string;
    longitude: string;
  };
  telephone?: string;
  description?: string;

  openingHoursSpecification?: OpeningHourSpecification[];
  address?: {
    '@type': 'PostalAddress';
    streetAddress: string; // '서울시 강남구 메인스트리트 123번길';
    addressLocality?: string; // '서울';
    addressRegion?: string; // '강남구';
    addressCountry: '대한민국'; // '대한민국';
  };
  url?: string;
  department?: string; // "내과, 소아과, 피부과"
}

export interface SearchKoreaAddressV2Vo {
  centroidLatitude: number;
  centroidLongitude: number;
  displayName: string;
  regionCode: string;
  regionName: string;
}

export interface RegionByDepthVo {
  siDoName?: string;
  siGunGuName?: string;
  legalEupMyeonDongName?: string;
}

export interface RegionName {
  siDoName: string;
  siGunGuName?: string;
  dongName?: string;
}

export interface SelectedRegion {
  siDo: SearchKoreaAddressV2Vo | null;
  siGunGu: SearchKoreaAddressV2Vo | null;
  dong: SearchKoreaAddressV2Vo | null;
}

export interface NaverReviewVo {
  id: string;
  title: string;
  content: string;
  postedAt: string;
  url: string;
}

export interface HospitalSearchQueries {
  keyword?: string;
  latitude?: string;
  longitude?: string;
  sortType?: string;
  mode?: string;
  commonFilter?: string;
}

export interface BookingFormVo {
  bookingId: string;
  targetDate: string;
  targetDateDisplayText: string;
  detailSymptom?: string | null;
  name: string;
  registrationNumber: string;
  address: string;
  detailAddress?: string | null;
  hospital: { name: string };
  doctor: { name: string; medicineType: MedicineType };
  booking: { status: BookingStatus };
  diagnosis?: { status: FaceToFaceDiagnosisStatus } | null;
}
export enum HospitalSearchSortType {
  ACCURACY = 'ACCURACY',
  DISTANCE = 'DISTANCE',
  REVIEW_AVERAGE_RATING = 'REVIEW_AVERAGE_RATING',
  REVIEW_COUNT = 'REVIEW_COUNT',
}

export enum HospitalSearchParamsQueries {
  SortType = 'sortType',
  CommonFilter = 'commonFilter',
  Keyword = 'keyword',
  Mode = 'mode',
}

export enum HospitalSearchMode {
  NEAR = 'NEAR',
  SEARCH = 'SEARCH',
}

export enum HospitalFreeInfluenzaType {
  Child = 'Child',
  Pregnant = 'Pregnant',
  Senior = 'Senior',
  SeongnamCitizen = 'SeongnamCitizen',
}

export enum HospitalInfluenzaFilter {
  FreeInfluenza = 'FreeInfluenza',
}

export enum HospitalCommonFilterType {
  Specialist = 'specialist',
  Bookable = 'bookable',
  Operating = 'operating',
  NightOperation = 'nightOperation',
  SundayOperation = 'sundayOperation',
  NonF2f = 'nonF2f',
}

export type HospitalSearchCommonFilterMap = {
  [key in HospitalCommonFilterType]: boolean;
};

type HospitalInfluenzaFiltersType = {
  [HospitalInfluenzaFilter.FreeInfluenza]: FreeInfluenzaFilter;
};

export type InfluenzaFilterType<T extends HospitalInfluenzaFilter> =
  HospitalInfluenzaFiltersType[T];

export interface EnumCommonFilter<VoType, DtoType> {
  readonly type: VoType;
  readonly dtoType: DtoType;
  getTitle(): string;
  isSelected(selectedType?: VoType): boolean;
}
export interface MedicalSubCategoryVo {
  id: string;
  keyword?: string | null;
  name: string;
  nonBenefitCategoryId?: number | null;
  nonBenefitItemId?: number | null;
  prescriptionUnit?: number | null;
  searchInflowType: string;
}

export interface MedicalCategoryVo {
  id: string;
  name: string;
  iconUrl: string;
  description: string;
  inflowType: string;
  iconType: 'ANIMATION' | 'PLAIN_IMAGE';
  departmentGroupId?: string | null;
  label?: string | null;
  medicalSubCategories: MedicalSubCategoryVo[];
}

export interface HospitalTreatmentVo {
  id: string;
  name: string;
}
export interface HospitalAdBannerVo {
  displayDiscount: string;
  hospitalId: string;
  id: string;
  displayPrice: string;
  thumbnailImageUrl: string;
  title: string;
  url: string;
}

export interface NonBenefitHospitalVo {
  hospitalId: number;
  hospitalPublicId: string;
  hospitalName: string;
  hospitalAddress: string;
  hospitalDistance?: number;
  hospitalRepresentativeImageUrl?: string;
  isBookable: boolean;
  hospitalOperationStatus: DiagnosisState;
  reviewTotalCount: number;
  reviewScore: string;
  hospitalBadges: string[];
  operationDisplay?: string;
  hospitalRepresentativeDepartmentName: string;
  hospitalAdBanners: HospitalAdBannerVo[];
  nonBenefitPriceInfo?: {
    diagnosisPrice?: string;
    totalPrice?: string;
  };
}

export interface NonBenefitCategoryPrescriptionUnitVo {
  unit: number;
  unitType: NonBenefitItemUnitType;
  unitDisplayMessage: string;
  unitId: string;
}

export interface UnitPriceVo {
  unitDisplayMessage: string;
  diagnosisPrice: string;
  totalPrice: string;
}

export interface NonBenefitCategoryVo {
  categoryId: string;
  categoryName: string;
  imageUrl?: string | null;
  nonBenefitItemGroup: {
    itemId: string;
    itemName: string;
    hasMedicinePrice: boolean;
    unitPrices: Array<UnitPriceVo>;
  };
}

export interface NonBenefitCategoryByIdItemVo {
  id: string;
  isNeedPrescription: boolean;
  isRepresentativeOfCategory: boolean;
  name: string;
  nonBenefitCategoryId: string;
}

export interface DoctorBookingTime {
  doctor: {
    doctorId: string;
    name: string;
  };
  bookingTimes: Array<BookingTimeSlotResponse>;
}

export type BookingsMap<T = number> = Map<
  T,
  {
    doctors: HospitalDoctorResponse[];
    bookingTime: BookingTimeSlotResponse;
  }
>;

export enum HospitalDetailModal {
  Booking = 'booking',
}
export interface HospitalDetailQueries {
  type?: string;
  departmentGroupId?: string;
  nonBenefitCategoryId?: string;
  modal?: HospitalDetailModal;
}

export interface HospitalEventQueries {
  eventUrl?: string;
  hospitalPid?: string;
  departmentGroupId?: string;
  type?: string;
}

export interface NeighborhoodHospitalQueries {
  keyword?: string;
  regionCode?: string;
  type?: NeighborhoodHospitalType;
  filter?: NeighborhoodHospitalFilter;
}
