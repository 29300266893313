import { get } from 'lodash';

import { BFF_ERROR_CODE_TYPES, ERROR_MESSAGE } from '@/constants/errors';
import { GraphqlError } from '@/types/error';

export interface GraphqlErrors {
  extensions: { code: string };
  message: string;
  path: Array<string>;
}

export const getBffErrorMessage = (error?: GraphqlError) => {
  const errorData = error as GraphqlError;
  if (errorData.code && errorData.code in ERROR_MESSAGE) {
    return get(ERROR_MESSAGE, errorData.code);
  }

  if ((errorData as GraphqlError).message) {
    return (errorData as GraphqlError).message;
  }

  return get(ERROR_MESSAGE, 'DefaultError');
};

export const getErrorObject = (error: unknown) => {
  let errorObject = undefined;
  if (error instanceof Error) {
    errorObject = {
      ...error,
    };
  }
  return errorObject;
};
