import styled from 'styled-components';

const BrandDescriptionSection = () => {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image
          data-aos={'fade-up'}
          data-aos-duration={2000}
          src={'https://file.doctornow.co.kr/landing/brand-description-logo.png'}
          alt="닥터나우 브랜드 이미지"
        />
      </ImageWrapper>
      <Title>
        대한민국 1위
        <br />
        비대면 진료 어플
        <span>{'\n닥터나우'}</span>
      </Title>
    </Wrapper>
  );
};

export default BrandDescriptionSection;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 120px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.color.white};
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.color.white};
`;

const Image = styled.img`
  object-fit: contain;
  width: 200px;
  height: 200px;
`;

const Title = styled.h1`
  display: -webkit-box;
  align-self: center;
  text-align: center;

  color: ${({ theme }) => theme.color.gray700};
  ${({ theme }) => theme.typography.UI32B};

  & span {
    color: ${({ theme }) => theme.color.primary600};
    align-self: stretch;
    white-space: pre-line;
  }
`;
