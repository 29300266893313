import styled, { keyframes } from 'styled-components';

import StarIcon from '@/components/atom/Landing/StarIcon';
import { MockReview } from '@/components/templates/landing/useLandingViewModel';
import { Tablet } from '@/styles/layout';

interface Props {
  nestedReviews: MockReview[][];
}

const ReviewSection = ({ nestedReviews }: Props) => {
  return (
    <Section>
      <Content>
        <Title>
          {'닥터나우는\n수많은 분들의 '}
          <Highlight>신뢰를 받고 있어요</Highlight>
        </Title>
        <Summary>
          <SummaryContent>
            <SummaryContentBox>
              <Label>평점</Label>
              <RateRow>
                <StarIcon />
                <RateBox>
                  <BigRate>4.8</BigRate>
                  <SmallRate>/5</SmallRate>
                </RateBox>
              </RateRow>
            </SummaryContentBox>
            <Separator />
            <SummaryContentBox>
              <Label>닥터나우 진료 후기 수</Label>
              <ReviewCount>250,000+</ReviewCount>
            </SummaryContentBox>
          </SummaryContent>
          <SummaryAsOf>
            * 구글플레이, 앱스토어 평점
            <br />
            2024년 12월 기준
          </SummaryAsOf>
        </Summary>
      </Content>
      <ReviewRows>
        <RowViewPort>
          <Row>
            <RowContainer>
              {nestedReviews[0].map((review, index) => (
                <Review key={`review-${review.author}-${review.title}-${index}`}>
                  <ReviewBox>
                    <TagAndTitle>
                      <Tag>
                        <TagLogo src={review.icon} alt={review.alt} />
                        {review.tag}
                      </Tag>
                      <ReviewTitle>{review.title}</ReviewTitle>
                    </TagAndTitle>
                    <ReviewContent>{review.content}</ReviewContent>
                  </ReviewBox>
                  <ReviewAuthor>{review.author}</ReviewAuthor>
                </Review>
              ))}
            </RowContainer>
            <RowContainer>
              {nestedReviews[0].map((review, index) => (
                <Review key={`review-${review.author}-${review.title}-${index}`}>
                  <ReviewBox>
                    <TagAndTitle>
                      <Tag>
                        <TagLogo src={review.icon} alt={review.alt} />
                        {review.tag}
                      </Tag>
                      <ReviewTitle>{review.title}</ReviewTitle>
                    </TagAndTitle>
                    <ReviewContent>{review.content}</ReviewContent>
                  </ReviewBox>
                  <ReviewAuthor>{review.author}</ReviewAuthor>
                </Review>
              ))}
            </RowContainer>
          </Row>
        </RowViewPort>
        <RowViewPort>
          <ReverseRow>
            <RowContainer>
              {nestedReviews[1].map((review, index) => (
                <Review key={`review-${review.author}-${review.title}-${index}`}>
                  <ReviewBox>
                    <TagAndTitle>
                      <Tag>
                        <TagLogo src={review.icon} alt={review.alt} />
                        {review.tag}
                      </Tag>
                      <ReviewTitle>{review.title}</ReviewTitle>
                    </TagAndTitle>
                    <ReviewContent>{review.content}</ReviewContent>
                  </ReviewBox>
                  <ReviewAuthor>{review.author}</ReviewAuthor>
                </Review>
              ))}
            </RowContainer>
            <RowContainer>
              {nestedReviews[1].map((review, index) => (
                <Review key={`review-${review.author}-${review.title}-${index}`}>
                  <ReviewBox>
                    <TagAndTitle>
                      <Tag>
                        <TagLogo src={review.icon} alt={review.alt} />
                        {review.tag}
                      </Tag>
                      <ReviewTitle>{review.title}</ReviewTitle>
                    </TagAndTitle>
                    <ReviewContent>{review.content}</ReviewContent>
                  </ReviewBox>
                  <ReviewAuthor>{review.author}</ReviewAuthor>
                </Review>
              ))}
            </RowContainer>
          </ReverseRow>
        </RowViewPort>
      </ReviewRows>
    </Section>
  );
};

export default ReviewSection;

const Section = styled.div`
  padding: 96px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 72px;

  ${({ theme }) => `
    background: ${theme.color.gray40};
  `} @media screen and (
    max-width: ${Tablet}) {
    padding: 80px 0;
  }
`;

const Content = styled.div`
  display: flex;
  padding-bottom: 0;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  @media screen and (max-width: ${Tablet}) {
    gap: 32px;
  }
`;

const Title = styled.div`
  text-align: center;
  display: inline-block;
  white-space: pre-wrap;
  ${({ theme }) => `
        ${theme.typography.UI48B};
        color: ${theme.color.gray900};
        
      @media screen and (max-width: ${Tablet}) {
          width: 300px;
          text-align: left;
          display: flex;
          flex-direction:column;
          align-self:flex-start;
          ${theme.typography.UI32B};
        }
    `}
`;

const Highlight = styled(Title)`
  ${({ theme }) => `
        color: ${theme.color.primary700};
    `}
`;

const Summary = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const SummaryContent = styled.div`
  display: flex;
  padding: 0;
  align-items: flex-start;
  gap: 20px;
`;

const SummaryContentBox = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
`;
const Label = styled.div`
  ${({ theme }) => `
      ${theme.typography.UI20SB};
      color: ${theme.color.gray500};
      
    @media screen and (max-width: ${Tablet}) {
       ${theme.typography.UI16SB};
      }
    `}
`;

const RateRow = styled.div`
  display: flex;
  padding: 0;
  align-items: center;
  gap: 8px;
`;
const RateBox = styled.div`
  display: flex;
  padding: 0;
  align-items: flex-end;
  gap: 2px;
`;

const BigRate = styled.div`
  ${({ theme }) => `
      ${theme.typography.UI40B};
      color: ${theme.color.gray700};
      
      @media screen and (max-width: ${Tablet}) {
         ${theme.typography.UI32B};
      }
    `}
`;
const SmallRate = styled.div`
  padding-bottom: 5%;
  ${({ theme }) => `
      ${theme.typography.UI20B};
      color: ${theme.color.gray700};
      
        @media screen and (max-width: ${Tablet}) {
         ${theme.typography.UI17B};
      }
    `}
`;

const Separator = styled.div`
  width: 1px;
  align-self: stretch;
  background: ${({ theme }) => theme.color.gray100};
`;

const ReviewCount = styled.div`
  ${({ theme }) => `
      ${theme.typography.UI40B};
      color: ${theme.color.gray700};
      
      @media screen and (max-width: ${Tablet}) {
      ${theme.typography.UI32B};
      }
    `}
`;

const SummaryAsOf = styled.div`
  align-self: center;
  text-align: center;
  ${({ theme }) => `
     ${theme.typography.UI16R};
     color: ${theme.color.gray300};
     
     @media screen and (max-width: ${Tablet}){
      align-self: flex-start;
      ${theme.typography.UI13R};
     }
    `}
`;

const RowViewPort = styled.div`
  overscroll-behavior-x: contain;
  width: 100%;
  display: flex;
  padding: 0;
  align-items: center;
  gap: 20px;
  overflow: hidden;
`;

const ReviewRows = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  ${RowViewPort} {
    padding-bottom: 20px;
  }

  ${RowViewPort}:last-child {
    padding-bottom: 40px;
  }
`;

const scrollLeftPc = keyframes`
    from {
        right: 0;
    }
    to {
        right: 3000px;
    }
`;

const scrollLeftMobile = keyframes`
    from {
        right: 0;
    }
    to {
        right: 2220px;
    }
`;

const scrollRightPc = keyframes`
    from {
        left: -3000px;
    }
    to {
        left: 0;
    }
`;

const scrollRightMobile = keyframes`
    from {
        left: -2220px;
    }
    to {
        left: 0;
    }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 20px;

  animation: ${scrollLeftPc} 20s linear infinite;

  @media screen and (max-width: ${Tablet}) {
    animation: ${scrollLeftMobile} 20s linear infinite;
  }
`;

const ReverseRow = styled(Row)`
  animation: ${scrollRightPc} 20s linear infinite;

  @media screen and (max-width: ${Tablet}) {
    animation: ${scrollRightMobile} 20s linear infinite;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
`;

const Review = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 480px;
  padding: 32px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  align-self: stretch;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);

  @media screen and (max-width: ${Tablet}) {
    width: 350px;
    padding: 24px;
  }
`;

const TagAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  @media screen and (max-width: ${Tablet}) {
    gap: 4px;
  }
`;

const TagLogo = styled.img`
  width: 24px;
  height: 24px;
`;

const Tag = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 7px;

  ${({ theme }) => `
      background ${theme.color.gray20};

      ${theme.typography.UI16SB};
      color: ${theme.color.gray500};
      
       @media screen and (max-width: ${Tablet}) {
        ${theme.typography.UI14SB};
        padding: 6px;
      }
    `}
`;

const ReviewTitle = styled.div`
  ${({ theme }) => `
      ${theme.typography.UI20B};
      color: ${theme.color.gray700};
      
       @media screen and (max-width: ${Tablet}) {
        ${theme.typography.UI18B};
      }
    `}
`;

const ReviewContent = styled.div`
  word-break: break-all;
  ${({ theme }) => `
      ${theme.typography.UI17R};
      color: ${theme.color.gray500};
      
     @media screen and (max-width: ${Tablet}) {
        ${theme.typography.UI15R};
      }
    `}
`;

const ReviewBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: ${Tablet}) {
    gap: 8px;
  }
`;

const ReviewAuthor = styled.div`
  ${({ theme }) => `
      ${theme.typography.UI15R};
      color: ${theme.color.gray400};
      
      @media screen and (max-width: ${Tablet}) {
        ${theme.typography.UI13R};
      }
    `}
`;
