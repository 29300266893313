import * as Types from '../../types/bff/gql-schema';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { gqlFetcher } from '@/utils/fetcher';

export const UserDocument = `
    query User {
  user {
    accountId
    deviceId
    email
    invitationCode
    isVerified
    marketingReceiptAccepted
    notificationAllowed
    nightlyMarketingReceiptAccepted
    contentNotificationAllowed
    platform
    pushToken
    userId
    createdAt
    detail {
      birthDate
      doseNotificationAllowed
      gender
      isForeigner
      isNeedVerificationForDiagnosis
      name
      phone
      telemedicineEligibleGroupType
    }
    account {
      id
      loginType
      role
      status
      oAuthInfo {
        accountId
        id
        providerType
      }
    }
  }
}
    `;
export const useUserQuery = <
      TData = Types.UserQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.UserQueryVariables,
      options?: UseQueryOptions<Types.UserQuery, TError, TData>
    ) =>
    useQuery<Types.UserQuery, TError, TData>(
      variables === undefined ? ['User'] : ['User', variables],
      gqlFetcher<Types.UserQuery, Types.UserQueryVariables>(UserDocument, variables),
      options
    );

useUserQuery.getKey = (variables?: Types.UserQueryVariables) => variables === undefined ? ['User'] : ['User', variables];
;

useUserQuery.fetcher = (variables?: Types.UserQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.UserQuery, Types.UserQueryVariables>(UserDocument, variables, options);