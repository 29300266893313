export class GraphqlError extends Error {
  /**
   * @type {string}
   * 서버에서 내려온 코드를 확인할 수 있습니다.
   */
  code: string;

  constructor(message: string, code: string) {
    super(message);
    this.code = code;
  }
}
export enum ServerError {
  NotFound = 'NotFound',
  BadRequest = 'BadRequest',
}
