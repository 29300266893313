import '@material-symbols/font-400/rounded.css';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import ko from 'dayjs/locale/ko';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DefaultSeo } from 'next-seo';
import App, { AppContext } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import 'sanitize.css/sanitize.css';
import { ThemeProvider } from 'styled-components';
import 'swiper/css';
import 'swiper/css/pagination';

import ErrorBoundary from '@/components/atom/ErrorBoundary';
import GlobalModal from '@/components/common/Modal';
import NaverAnalyticsScript from '@/components/common/NaverAnalyticsScript';
import RootErrorFallback from '@/components/common/RootErrorFallback';
import Toast from '@/components/common/Toast';
import DefaultLayout from '@/components/layouts/DefaultLayout';
import DefaultGlobalSideBar from '@/components/layouts/GlobalSideBar';
import { IS_LOCAL } from '@/constants/env';
import useIsMounted from '@/hooks/useIsMounted';
import useLogger from '@/hooks/useLogger';
import GlobalStyle from '@/styles/globalStyle';
import '@/styles/globals.css';
import theme from '@/styles/theme';
import { Client, ClientType, CustomAppProps } from '@/types/app';
import { Nullable, ROUTES, isServer } from '@/types/common';
import Firebase from '@/utils/firebase';
import Hackle from '@/utils/hackle';
import { isAndroidByUserAgent, isMobile, isWebViewByUserAgent } from '@/utils/mobile/RNPlugins';
import Pixel from '@/utils/pixel';
import { initDatadogMonitoring } from '@/utils/report';
import { getUrlPathWithoutQueryString } from '@/utils/url';

import nextSeoConfig from '../../next-seo.config';

dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.locale(ko);

const isWebBrowserClient = (client: Client) => {
  return !client.isWebView && !isServer;
};

export default function MyApp({ Component, pageProps: { dehydratedState, ...pageProps } }: CustomAppProps) {
  const route = useRouter();
  const isMounted = useIsMounted();
  const [prevPageName, setPrevPageName] = useState<Nullable<string>>();
  const isAirbridgeLink = route.query['airbridge_referrer'];
  const { userAgent, client } = pageProps;
  const logger = useLogger();

  if (isWebBrowserClient(client)) {
    Firebase.initApp(userAgent);
  }

  const initializeRecoilState = () => {
    dayjs.extend(isBetween);
  };

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: 0,
          refetchOnWindowFocus: false,
          useErrorBoundary: false,
          refetchOnReconnect: false,
        },
        mutations: {
          retry: 0,
        },
      },
    }),
  ).current;

  const Layout = Component.Layout ?? DefaultLayout;
  const Sidebar = Component.Sidebar ?? DefaultGlobalSideBar;

  useEffect(() => {
    if (isMounted) {
      const currentPageName = getUrlPathWithoutQueryString(route.asPath);
      if (prevPageName !== currentPageName) {
        setPrevPageName(currentPageName);
        logger.pageView(userAgent);
      }
    }
  }, [isMounted, route, prevPageName, logger]);

  useEffect(() => {
    if (!client.isWebView) {
      Pixel.initialize();
    }
    initDatadogMonitoring();
    Hackle.createHackle();
    if (IS_LOCAL) console.log('init', pageProps);
  }, [client.clientType]);

  useEffect(() => {
    if (isAirbridgeLink) {
      sessionStorage.setItem('airbridgeLink', JSON.stringify({ ...route.query }));
    }
  }, [isAirbridgeLink, route]);

  return (
    <>
      <DefaultSeo {...nextSeoConfig} />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={dehydratedState}>
          <RecoilRoot initializeState={initializeRecoilState}>
            <RecoilNexus />
            <ThemeProvider theme={{ ...theme, client }}>
              <ErrorBoundary renderFallback={RootErrorFallback} onReset={() => route.replace(ROUTES.BOARD.HOME.path)}>
                <GlobalStyle />
                <GlobalModal />
                <Toast userAgent={userAgent} />
                <NaverAnalyticsScript userAgent={userAgent} />
                <Layout userAgent={userAgent} client={client} customGlobalSideBar={<Sidebar />}>
                  <Component {...pageProps} />
                </Layout>
              </ErrorBoundary>
            </ThemeProvider>
          </RecoilRoot>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  // TODO: service_ 값들도 추후 Client 에 추가 필요 (로그 QA 필요하기에 해당 작업에서 제외됨)
  // 외부에서 사용하지 못하도록 하기위해 함수 내부에 선언
  const getClient = (userAgent: string | string[] | undefined): Client => {
    const defaultClient = {
      clientType: ClientType.WEB,
      isWebView: false,
      isMobile: false,
      isIOS: false,
      isAndroid: false,
    };

    if (typeof userAgent !== 'string') {
      return defaultClient;
    }

    if (isWebViewByUserAgent(userAgent)) {
      // 웹뷰
      return {
        clientType: ClientType.MOBILE_WEBVIEW,
        isWebView: true,
        isMobile: true,
        isIOS: !isAndroidByUserAgent(userAgent),
        isAndroid: isAndroidByUserAgent(userAgent),
      };
    }
    if (isMobile(userAgent)) {
      // 모바일 웹
      return {
        clientType: ClientType.MOBILE_WEB,
        isWebView: false,
        isMobile: true,
        isIOS: !isAndroidByUserAgent(userAgent),
        isAndroid: isAndroidByUserAgent(userAgent),
      };
    }
    // 웹
    return {
      clientType: ClientType.WEB,
      isWebView: false,
      isMobile: false,
      isIOS: false,
      isAndroid: false,
    };
  };

  const appProps = await App.getInitialProps(appContext);
  const userAgent = (await appContext.ctx.req)
    ? appContext.ctx.req?.headers['x-user-agent'] ?? appContext.ctx.req?.headers['user-agent']
    : navigator.userAgent;

  appProps.pageProps.userAgent = userAgent;
  appProps.pageProps.client = getClient(userAgent);

  return { ...appProps };
};
