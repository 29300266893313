import dayjs from 'dayjs';

import { useUserQuery } from '@/hooks/auth/auth.gql.impl';
import { User } from '@/types/Auth';
import {
  GenderType,
  PlatformType,
  ProviderType,
  UserQuery,
} from '@/types/bff/gql-schema';
import { GraphqlError } from '@/types/error';

const convertProvider = (provider?: ProviderType) => {
  switch (provider) {
    case ProviderType.Kakao:
      return 'kakao';
    case ProviderType.Naver:
      return 'naver';
    case ProviderType.Apple:
      return 'apple';
    default:
      return 'doctornow';
  }
};

const convertGender = (gender?: GenderType) => {
  switch (gender) {
    case GenderType.Female:
      return 'female';
    case GenderType.Male:
      return 'male';
    default:
      return 'none';
  }
};

const convertPlatform = (platform?: PlatformType) => {
  switch (platform) {
    case PlatformType.Android:
      return 'Android';
    case PlatformType.Ios:
      return 'iOS';
    case PlatformType.Web:
      return 'Web';
    default:
      return 'Unknown';
  }
};
const parseUserRealTime = (data: UserQuery): User => {
  const userInfo = data.user;
  return {
    id: Number(userInfo.userId),
    userId: userInfo.userId,
    email: userInfo.email ?? undefined,
    name: userInfo.detail?.name || '',
    phone: userInfo.detail?.phone || '',
    provider: convertProvider(
      userInfo.account.oAuthInfo?.providerType ?? undefined,
    ),
    deviceId: userInfo.deviceId || '',
    birth: userInfo.detail?.birthDate || '',
    gender: convertGender(userInfo.detail?.gender ?? undefined),
    isMarketing: userInfo.marketingReceiptAccepted,
    isNotify: userInfo.notificationAllowed,
    pushToken: userInfo.pushToken || '',
    isDoseNotify: userInfo.detail?.doseNotificationAllowed ?? false,
    isCertified: userInfo.isVerified,
    isNeedVerificationForDiagnosis:
      userInfo.detail?.isNeedVerificationForDiagnosis ?? false,
    createdAt: dayjs(userInfo.createdAt).toISOString(),
    platform: convertPlatform(userInfo.platform),
    username: userInfo.detail?.name || '',
    isContentNotify: userInfo.contentNotificationAllowed,
    isNightlyMarketing: userInfo.nightlyMarketingReceiptAccepted,
  };
};
const useUser = ({
  enabled,
  onSuccess,
  onError,
}: {
  enabled: boolean;
  onSuccess?: (data: User) => void;
  onError?: (error: GraphqlError) => void;
}) => {
  const { data } = useUserQuery(
    {},
    {
      select: parseUserRealTime,
      useErrorBoundary: false,
      retry: 0,
      enabled,
      onSuccess,
      onError,
    },
  );

  return {
    data,
  };
};

export default useUser;
